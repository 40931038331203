@import "_variables";
@import "_mixins";
body {
    background: $colorMainBackground;
    font-family: 'Titillium', sans-serif;
    color: #0A1D64;
}

.page-body {
    margin-right: 340px;
    margin-left: 60px;
    padding-top: 80px;
}


/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}


/* ==========================================================================
Imported styles
========================================================================== */

// <!-- inject:scss -->
@import "./src/main/webapp/app/login/login.scss";
@import "./src/main/webapp/app/components/checkbox/checkbox.scss";
@import "./src/main/webapp/app/components/selectize/selectize.scss";
@import "./src/main/webapp/app/components/table-fixed-header/table-fixed-header.scss";
@import "./src/main/webapp/app/entities/auction/auction.scss";
@import "./src/main/webapp/app/entities/bid/bid.scss";
@import "./src/main/webapp/app/entities/documentation/documentation.scss";
@import "./src/main/webapp/app/entities/offer/offer.scss";
@import "./src/main/webapp/app/entities/submission/submission.scss";
@import "./src/main/webapp/app/layouts/menu/menu.scss";
@import "./src/main/webapp/app/layouts/navbar/navbar.scss";
@import "./src/main/webapp/app/layouts/sidebar/sidebar.scss";
// <!-- endinject -->

/* ==========================================================================
Version number in navbar
========================================================================== */

.navbar-version {
    font-size: $ten-pixels;
    color: #ccc
}


/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


/* ==========================================================================
Logo styles
========================================================================== */

.navbar-brand {
    height: 60px;
    &.logo {
        padding: 5px 15px;
        .logo-img {
            height: 45px;
            display: inline-block;
        }
    }
}


/* ==========================================================================
Main page styles
========================================================================== */



/* wait autoprefixer update to allow simple generation of high pixel density media query */

/* ==========================================================================
Generic styles
========================================================================== */

.error {
    color: white;
    background-color: red;
}

.pad {
    padding: $ten-pixels;
}

.break {
    white-space: normal;
    word-break: break-all;
}

.voffset {
    margin-top: 2px;
}

.voffset1 {
    margin-top: $bigger-round;
}

.voffset2 {
    margin-top: $ten-pixels;
}

.voffset3 {
    margin-top: $inter-margin;
}

.voffset4 {
    margin-top: $medium-margin;
}

.voffset5 {
    margin-top: 40px;
}

.voffset6 {
    margin-top: 60px;
}

.voffset7 {
    margin-top: 80px;
}

.voffset8 {
    margin-top: 100px;
}

.voffset9 {
    margin-top: 150px;
}

.readonly {
    background-color: #eee;
    opacity: 1;
}


/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */

a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}


/* ==========================================================================
Metrics and Health styles
========================================================================== */

#threadDump .popover,
#healthCheck .popover {
    top: inherit;
    display: block;
    font-size: $ten-pixels;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}


/* ==========================================================================
start Password strength bar style
========================================================================== */

ul#strength {
    display: inline;
    list-style: none;
    margin: $none-border;
    margin-left: $inter-margin;
    padding: $none-border;
    vertical-align: 2px;
}

.point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: $bigger-round;
    margin-right: 1px;
    width: $twenty-pixels;
    &:last {
        margin: $none-border !important;
    }
}


/* ==========================================================================
Custom alerts for notification
========================================================================== */

.alerts {
    .alert {
        text-overflow: ellipsis;
        pre {
            background: none;
            border: none;
            font: inherit;
            color: inherit;
            padding: $none-border;
            margin: $none-border;
        }
        .popover pre {
            font-size: $ten-pixels;
        }
    }
    .toast {
        position: fixed;
        width: $all-of;
        &.left {
            left: $bigger-round;
        }
        &.right {
            right: $bigger-round;
        }
        &.top {
            top: 55px;
        }
        &.bottom {
            bottom: 55px;
        }
    }
}

.error-modal {
    .alerts {
        z-index: 100;
        width: 100%;
        position: static;
    }
}

.gsp-modal {
    .alerts {
        z-index: 100;
        width: 100%;
        position: static;
    }
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}


/* ==========================================================================
entity tables helpers
========================================================================== */


/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */

@mixin no-padding($side) {
    @if $side=='all' {
        .no-padding {
            padding: $none-border !important;
        }
    }
    @else {
        .no-padding-#{$side} {
            padding-#{$side}: 0 !important;
        }
    }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */

.width-min {
    width: 1% !important;
}


/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */

.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.jh-table> {
    tbody>tr>td {
        /* Align text in td verifically (top aligned by Bootstrap) */
        vertical-align: middle;
    }
    thead>tr>th> {
        .glyphicon-sort,
        .glyphicon-sort-by-attributes,
        .glyphicon-sort-by-attributes-alt {
            /* less visible sorting icons */
            opacity: 0.5;
            &:hover {
                /* full visible sorting icons and pointer when mouse is over them */
                opacity: 1;
                cursor: pointer;
            }
        }
    }
}


/* ==========================================================================
entity detail page css
========================================================================== */

.dl-horizontal.jh-entity-details> {
    dd {
        margin-bottom: $inter-margin;
    }
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details> {
        dt {
            margin-bottom: $inter-margin;
        }
        dd {
            border-bottom: 1px solid #eee;
            padding-left: 180px;
            margin-left: $none-border;
        }
    }
}


/* ==========================================================================
ui bootstrap tweaks
========================================================================== */

.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown>li.date-picker-menu div>table .btn-default,
.uib-datepicker-popup>li>div.uib-datepicker>table .btn-default {
    border: $none-border;
}

.datetime-picker-dropdown>li.date-picker-menu div>table:focus,
.uib-datepicker-popup>li>div.uib-datepicker>table:focus {
    outline: none;
}


/* jhipster-needle-scss-add-main JHipster will add new css style */

.alerts {
    z-index: 100;
    width: 50%;
    position: fixed;
    top: 70px;
    right: 370px;
}

@import "table";
@import "fonts";
@import "modal";
html,
body {
    min-width: 1300px;
}

h2.title {
    font-family: $aplication-font;
    color: #a3a3a3;
    font-size: $button-height;
    font-weight: 800;
    line-height: $inter-margin;
    text-align: left;
}

button {
    &.btn.gsp-button {
        background-color: $gsp-blue;
        border: none;
        font-family: $aplication-font;
        color: $white-font;
        font-size: $small-margin;
        padding: 6px 8px 6px 8px;
        height: 32px;
        &.gif-report-button {
            margin-bottom: $twenty-pixels;
        }
        &[disabled]:hover,
        &[disabled]:focus {
            background-color: $gsp-blue;
        }
    }
    &.btn.gsp-button.gsp-close {
        background-color: #000;
    }
    span.foo {
        display: inline-block;
    }
    span.foo+span {
        vertical-align: top;
    }
    .checkbox-in-button input {
        width: $hex-margin;
        height: $hex-margin;
        margin-top: 6px;
    }
    span.checkbox-in-button+span {
        vertical-align: text-bottom;
    }
}

button.back-login {
    margin-top: $bigger-round;
    min-height: 42px;
}

button.foo-icon {
    .foo {
        background-image: url("../images/action.icons.for-button.png");
    }
}

.foo {
    height: $button-height;
    width: $button-height;
    background: transparent;
    margin-top: -3px;
    background-image: url("../images/action.icons.png");
}

@include setBackgroundPosition('.foo');
.container-fluid.page-body {
    padding-left: $medium-margin;
    padding-right: $medium-margin;
}

.text-center {
    text-align: center !important;
    padding-left: $none-border;
    padding-right: $none-border;
}

.element-center {
    transform: translateY(50%);
}

.gsp-paginator {
    .pagination-sm {
        &.pagination {
            margin: $none-border;
        }
        li {
            display: inline-block;
            margin: 0 5px;
            &.pagination-page a{
                color: #56617F;
                border: none;
                background: #E6EAFF;
            }
            &.active {
                a:hover,
                a:focus,
                a {
                    background: $gsp-blue;
                    border: none;
                    color: white;
                }
            }
        }
    }
    margin-bottom: 40px;
}

.modal-body {
    select.ng-pristine.ng-valid.ng-not-empty {
        margin-top: $none-border;
    }
}

.deletingReason {
    label {
        &[required]:after {
            content: " *";
            color: red;
        }
    }
}

.announcement-auction span {
    @include annoucments;
    border: $gsp-light-border;
}

.announcement-auction-red span {
    @include annoucments;
    border: 1px #BB3620 solid;
}

.autocomplete-list {
    width: calc(100% - 30px);
    border: none;
    margin-left: $inter-margin;
    ul {
        li.autocomplete-active {
            background-color: $gsp-blue-very-light;
            font-weight: bold;
            border-radius: $bigger-round;
        }
    }
}

.dropdown-pagination {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 28px;
    background: $gsp-blue;
    border-radius: $bigger-round;
    text-align: center;
    padding-top: $bigger-round;
    &:before {
        color: $white-font;
    }
    .dropdown-content-pagination {
        display: none;
        position: absolute;
        right: $none-border;
        background-color: #f9f9f9;
        padding: $bigger-round;
        border-radius: $bigger-round;
        min-width: 122px;
        top: 25px;
        border: 1px solid #DDD;
        button:not(:last-child) {
            margin-right: $bigger-round;
        }
    }
    &:hover .dropdown-content-pagination {
        display: flex;
    }
}

.input-group-btn .calendar {
    background: url("../images/calendar.png");
    height: 34px;
    width: 40px;
    background-repeat: no-repeat;
    background-position: 50% 40%;
}

.form-control::-ms-clear {
    display: none;
}

.form-control:focus {
    border-color: $accent;
    -webkit-box-shadow: inset 0 1px 1px $accent;
    box-shadow: inset 0 0 4px $accent;
}

.control-group {
    &.supervizor {
        display: flex;
        padding-top: $twenty-pixels;
        .button-right {
            height: 32px;
            width: 25%;
            padding-right: $inter-margin;
            button {
                float: right;
            }
        }
        .form-group {
            display: flex;
            width: 25%;
            .datetime-picker-dropdown {
                min-width: 280px;
            }
            &.hours,
            &.minutes {
                margin: auto;
                padding: $bigger-round;
                width: 90px;
            }
            label {
                width: 100px;
                padding: 6px 0px 6px 20px;
            }
            .selectize-input {
                min-height: 32px;
                .item {
                    width: calc(100% - 30px);
                    .phase {
                        @include phases(left,
                        5px);
                    }
                }
            }
            .selectize-dropdown-content .option .phase {
                @include phases(left,
                5px);
            }
            .input-group {
                width: calc(100% - 100px);
                min-width: 180px;
            }
            .selectize-control {
                width: calc(100% - 100px);
                min-width: 170px;
            }
        }
    }
    &.charges {
        @extend .supervizor;
        .form-group {
            select {
                width: calc(100% - 100px);
                min-width: 170px;
            }
            &:last-child {
                margin-right: $inter-margin !important;
            }
            &.not-last {
                margin-right: 1px;
                .selectize-control {
                    width: calc(100% - 100px);
                    min-width: 165px;
                }
            }
            .selectize-control {
                width: calc(100% - 100px);
                min-width: 145px;
            }
        }
    }
    &.billings {
        .selectize-input {
            min-height: 32px;
            .item {
                width: calc(100% - 30px);
                .phase {
                    @include phases(left,
                    5px);
                }
            }
        }
        .selectize-dropdown-content .option .phase {
            @include phases(left,
            5px);
        }
    }
}

.button-container {
    display: inline;
    div {
        display: inline-block;
        &:first-child {
            padding-left: $inter-margin;
        }
        &:last-child {
            padding-right: $inter-margin;
            float: right;
        }
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .date-picker-menu+li span {
        margin-bottom: $bigger-round;
    }
}

.datetime-picker-dropdown {
    .uib-daypicker,
    .time-picker-menu {
        margin-top: $bigger-round;
    }
}

.alert-info2 {
    background-color: #DDE7C9;
    border-color: #DDE7C9;
    color: black;
}

/* enable absolute positioning */
.inner-addon {
    position: relative;
}

/* style icon */
.inner-addon .icon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
  top: 4px;
}

/* align icon */
.left-addon .icon  { left:  0px;}
.right-addon .icon { right: 0px;}

/* add padding  */
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }

label{
    font-weight: normal;
}

.description-text {
    white-space: pre-wrap;
}

.increment-btn {
    height: 34px;
    width: 40px;
}

.locked-icon {
    background-image: url("../images/icons/password_icon.png");
    background-position: 5px 5px;
    background-repeat: no-repeat;
}

p.table-help-block {
    background: #ffffff;
    border: 1px solid red;
    border-radius: $bigger-round;
    box-shadow: 1px 3px 6px rgba(100, 0, 0, 0.4);
    font-size: 12px;
    margin-top: 5px;
    padding: 8px;
    position: absolute;
    white-space: pre-line;
    z-index: 1000;
    &:before {
        content: url("../images/warning.png");
    }
}

// TODO z-index change in other places
.dropdown-menu {
    z-index: 1051 !important;
}

.dropdown-content-pagination {
    z-index: 1052 !important;
}
