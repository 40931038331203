.offer-description-ellipsis {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tables {
    margin-top: 10px;
    .table-responsive.gsp-table {
        table.table-striped.table {
            margin: 10px 0 0 0;
        }
    }

    .fixed-width tr td:first-child {
        width: 30% !important;
    }

    .fixed-width tr td:nth-child(2) {
        width: 70% !important;
    }
}

.modal.offer-dialog {
    .modal-dialog {
        min-width: 1150px;
    }
}

.offer-parameters-body .glyphicon {
    font-size: 10px;
}

.table-nested {
    margin-bottom: 0 !important;
    tbody > tr > td:first-child {
        width: 2%;
        white-space: nowrap;
    }

    label {
        margin-bottom: 0;
    }

    .cell-branch {
        border-right: 0 !important;
    }
    .cell-detail {
        padding: 0 !important;
        border-left: 0 !important;
    }

    fieldset {
        .table {
            margin-bottom: 5px;
        }
    }

    .detail-content {
        ul,
        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        ul {
            li {
                border-left: 1px dashed #ff6309;
                display: flex;
                .detail {
                    margin: 0;
                    padding: 0 5px 0 0;
                    flex-grow: 1;
                    flex-basis: 100%;
                    fieldset {
                        legend {
                            font-size: 90%;
                            margin-left: 5px;
                            width: auto;
                            margin-bottom: 0;
                            border: none;
                            .cluster-tree {
                                background-color: #002D74;
                            }
                            .submission-tree {
                                background-color: #FF6309;
                            }
                        }
                        > div {
                            table.table-condensed {
                                tbody > tr > td {
                                    padding: 4px;
                                }
                            }
                        }
                    }
                    &:first-child {
                        border-top: 1px dashed #ff6309;
                        flex-basis: 13px;
                        background: #fbfbfb;
                        margin-top: 28px;
                    }
                }
                .long:first-child {
                    padding-right: 10px !important;
                    flex-basis: 77px !important;
                }

                &:first-child {
                    padding-top: 5px;
                }
                &:last-child {
                    margin-bottom: 0;
                    > .detail {
                        margin-bottom: 0;
                        &:first-child {
                            margin-left: -1px;
                        }
                    }
                }
                &.cluster-list {
                    border-left: 1px dashed #002D74;
                    > .detail:first-child {
                        border-top: 1px dashed #002D74;
                    }
                }
            }
        }
    }

    .tr-detail {
        > td {
            padding: 0 !important;
            padding-bottom: 10px !important;
            border-top: none !important;
            &:not(:first-child) {
                padding: 5px 5px 5px 5px;
            }
        }
    }

    .cell-action-buttons {
        padding: 1px 0 0 0 !important;
    }
}

.offer-parameters-table {
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.SAVED  {
        background: #FFFFFF; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.ACCEPTED  {
        background: #33CC33; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.ALLOCATION_GRANTED {
        background: #33CC33; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.ALLOCATION_REJECTED {
        background: #33CC33; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.SUBMITTED  {
        background: #FFE014; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.TO_IMPROVE  {
        background: #FFE014; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.WITHDRAWN {
        background: #343434; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.REJECTED  {
        background: #BB3620; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.SETTLED_REJECTED {
        background: #BB3620; }
    .table-responsive.gsp-table .table-striped.table tbody tr td div.phase.SETTLED_ALLOCATED {
        background: #7DACF6; }
}
