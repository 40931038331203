.selectize-dropdown {
    z-index: 2000;
}

.selectize-option {
    display: block;
    float: left;
    max-width: 85%;
    overflow: hidden;
    .option-name {
        display: block;
        float: left;
        margin-right: 5px;
    }
    .option-description {
        display: block;
        float: left;
        overflow: hidden;
        opacity: 0.5;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.selectize-options {
    .option-description {
        opacity: 0.5;
    }
}

.selectize-input {
    .item {
        padding-right: 5px;
    }
}
