@mixin menu-icons($selector, $x: 0, $y: -40px) {
    #{$selector}[data-url=""]:before {                      background-position: $x $y*0; }
    #{$selector}[data-url="order-log-file"]:before {        background-position: $x $y*1; }
    #{$selector}[data-url="instruments"]:before {           background-position: $x $y*2; }
    #{$selector}[data-url="members"]:before {               background-position: $x $y*3; }
    #{$selector}[data-url="users"]:before {                 background-position: $x $y*4; }
    #{$selector}[data-url="accounts"]:before {              background-position: $x $y*5; }
    #{$selector}[data-url="quotation-calendar"]:before {    background-position: $x $y*6; }
    #{$selector}[data-url="roles"]:before {                 background-position: $x $y*7; }
    #{$selector}[data-url="administration"]:before {        background-position: $x $y*8; }
    #{$selector}[data-url="reports"]:before {               background-position: $x $y*0; }
	#{$selector}[data-url="otc-reports"]:before {           background-position: $x $y*3; }
    #{$selector}[data-url="reports-account"]:before {       background-position: $x $y*9; }
    #{$selector}[data-url="import-exchange-reports"]:before { background-position: $x $y*2; }
    #{$selector}[data-url="import-otc-reports"]:before {    background-position: $x $y*5; }
    #{$selector}[data-url="payments-definitions"]:before {  background-position: $x $y*10; }
    #{$selector}[data-url="transactions-list"]:before {     background-position: $x $y*6; }
    #{$selector}[data-url="reports-details"]:before {       background-position: $x $y*1; }
    #{$selector}[data-url="otc-reports-details"]:before {   background-position: $x $y*4; }
    #{$selector}[data-url="mail-groups"]:before {           background-position: $x $y*13; }
    #{$selector}[data-url="payments-calculated"]:before {   background-position: $x $y*14; }
    #{$selector}[data-url="payments-exchange"]:before {     background-position: $x $y*10; }
    #{$selector}[data-url="payments-otc"]:before {          background-position: $x $y*10; }
    #{$selector}[data-url="backloading"]:before {           background-position: $x $y*7; }
    #{$selector}[data-url="backloading-report-account"]:before {         background-position: $x $y*7; }
    #{$selector}[data-url="backloading-report-details"]:before {         background-position: $x $y*13; }
    #{$selector}[data-url="otc-backloading"]:before {       background-position: $x $y*8; }
    #{$selector}[data-url="otc-backloading-general"]:before {background-position: $x $y*8; }
    #{$selector}[data-url="otc-backloading-details"]:before {background-position: $x $y*12; }
    #{$selector}[data-url="excluded-instruments"]:before {  background-position: $x $y*16; }
    #{$selector}[data-url="contact"]:before {               background-position: $x $y*9; }
    #{$selector}[data-url="acer-products"]:before {         background-position: $x $y*11; }
}

@mixin icon($semanticName, $faVar, $cursor: default, $color: inherit) {
    .icon-#{$semanticName}:before {
        content: "#{$faVar}";
        cursor: $cursor;
        color: $color;
    }
}

@mixin linear-gradient($fromColor, $toColor) {
    background-color: $toColor; /* Fallback Color */
    background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient(top, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image:    -moz-linear-gradient(top, $fromColor, $toColor); /* FF3.6 */
    background-image:     -ms-linear-gradient(top, $fromColor, $toColor); /* IE10 */
    background-image:      -o-linear-gradient(top, $fromColor, $toColor); /* Opera 11.10+ */
    background-image:         linear-gradient(top, $fromColor, $toColor);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin animate($property: all, $duration: 0.2s, $easing: ease-in-out, $delay: 0s) {
    -webkit-transition: $property $duration $easing $delay;
       -moz-transition: $property $duration $easing $delay;
        -ms-transition: $property $duration $easing $delay;
         -o-transition: $property $duration $easing $delay;
            transition: $property $duration $easing $delay;
}

@mixin placeholder($selector, $color){
    #{$selector}::-webkit-input-placeholder { /* WebKit browsers */
        color: $color;
    }
    #{$selector}:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
       color: $color;
       opacity: 1;
    }
    #{$selector}::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: $color;
       opacity: 1;
    }
    #{$selector}:-ms-input-placeholder { /* Internet Explorer 10+ */
       color: $color;
    }
}

@mixin transform($transforms) {
-webkit-transform: $transforms;
   -moz-transform: $transforms;
    -ms-transform: $transforms;
     -o-transform: $transforms;
        transform: $transforms;
}

@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

@mixin toggle-button-base() {
    @include button(
        $padding: rem-calc(5),
        $bg: $colorFilterToggleButtonBg
    );
    font-size: rem-calc(14);
    color: $colorFilterToggleButtonText;
    text-transform: uppercase;
    position: absolute;
    top: 0px;
    right: 20px;
    z-index: 1;
    width: 150px;
    &:focus {
        color: $colorFilterToggleButtonText;
        background-color: $colorFilterToggleButtonBg;
    }
    &:hover {
        color: $colorFilterToggleButtonTextHover;
        background-color: $colorFilterToggleButtonBgHover;
    }
}

@mixin toggle-button() {
    @include toggle-button-base;
    &:hover:after {
        background-position: -15px 0;
    }
    &:after {
        @include animate($property: transform);
        content: '';
        background-image: url(../../images/icons/interface.icons.png);
        background-position: 0 -25px;
        margin-top: 4px;
        float: right;
        margin-left: 10px;
        width: 15px;
        height: 9px;
    }
    &.collapsed:after {
        @include rotate(180);
    }
}

@mixin setLoginBackground($color){
    background-image: -ms-linear-gradient(top, white 20%, $color 100%);
    background-image: -moz-linear-gradient(top, white 20%, $color 100%);
    background-image: -o-linear-gradient(top, white 20%, $color 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0.2, white), color-stop(1, $color));
    background-image: -webkit-linear-gradient(top, white 20%, $color 100%);
    background-image: linear-gradient(to bottom, white 20%, $color 100%);
}

$aplication-font: 'Titillium', sans-serif;
$white-font: #ffffff;
$button-height:24px;
$small-margin:14px;
$inter-margin:15px;
$hex-margin:16px;
$medium-margin:30px;
$gsp-blue:#002D74;
$gsp-blue-light:rgba(36, 84, 187, 0.75);
$gsp-blue-very-light:rgba(36, 96, 187, 0.3);
$grey-background:#e5e5e5;
$light-grey-background:#F2F2F2;
$round-border:4px;
$bigger-round:5px;
$new-color:#FFFFFF;
$published-color:#FFE014;
$running-color:#33CC33;
$settled-color:#7DACF6;
$finished-color:#D1601F;
$invalidated-color:#BB3620;
$deleted-color:#E5E5E5;
$confirmation-color:#494949;
$custom-color:#CCFFFF;
$gsp-light-border: 1px #002D74 solid;
$table-border:1px #b8c4d0 solid;
$sidebar-border:1px solid #D8D8D8;
$sidebar-border-red: 1px solid red;
$language-border:1px solid #777;
$none-border:0px;
$twenty-pixels:20px;
$ten-pixels:10px;
$all-of:100%;

@mixin setBackgroundPosition($selector, $x: 0px, $y:-24px){
    #{$selector}.foo-delete        {background-position: $x $y*1;}
    #{$selector}.foo-edit          {background-position: $x $y*2;}
    #{$selector}.foo-view          {background-position: $x $y*3;}
    #{$selector}.foo-download      {background-position: $x $y*4;}
    #{$selector}.foo-start         {background-position: $x $y*5;}
    #{$selector}.foo-terminate     {background-position: $x $y*6;}
    #{$selector}.foo-add           {background-position: $x $y*7 + 1 ;}
    #{$selector}.foo-agree         {background-position: $x $y*8 + 2;}
    #{$selector}.foo-distribute    {background-position: $x $y*13;}
    #{$selector}.foo-settle        {background-position: $x $y*14;}
    #{$selector}.foo-reports       {background-position: $x $y*19;}
    #{$selector}.foo-logout        {color: $gsp-blue;}
}

@mixin commentColor($selector){
    #{$selector}.AUCTION_CREATED                {background:rgba(255,255,255, 0.2);}
    #{$selector}.OFFER_CREATED                  {background:rgba(255,255,255, 0.2);}
    #{$selector}.AUCTION_READY_TO_START         {background:rgba(235,235,0, 0.2);}
    #{$selector}.OFFER_READY_TO_START           {background:rgba(235,235,0, 0.2);}
    #{$selector}.AUCTION_STARTED                {background:rgba(51,204,51, 0.2);}
    #{$selector}.OFFER_STARTED                  {background:rgba(51,204,51, 0.2);}
    #{$selector}.AUCTION_FINISHED               {background:rgba(209,96,31, 0.2);}
    #{$selector}.OFFER_FINISHED                 {background:rgba(209,96,31, 0.2);}
    #{$selector}.AUCTION_SETTLEMENT             {background:rgba(125,172,246, 0.2);}
    #{$selector}.OFFER_SETTLEMENT               {background:rgba(125,172,246, 0.2);}
    #{$selector}.AUCTION_INVALIDATED            {background:rgba(187,54,32, 0.3);}
    #{$selector}.OFFER_INVALIDATED              {background:rgba(187,54,32, 0.3);}
    #{$selector}.AUCTION_DISTRIBUTED            {background:rgba(187,54,32, 0.2);}
    #{$selector}.OFFER_DISTRIBUTED              {background:rgba(187,54,32, 0.2);}
    #{$selector}.AUCTION_REQUIRES_CONFIRMATION  {background:rgba(73,73,73, 0.2);}
    #{$selector}.OFFER_REQUIRES_CONFIRMATION    {background:rgba(73,73,73, 0.2);}
    #{$selector}.AUCTION_DELETED                {background:rgba(229,229,229, 0.6);}
    #{$selector}.OFFER_DELETED                  {background:rgba(229,229,229, 0.6);}
    #{$selector}.CUSTOM_MANUAL                  {background:rgba(204,255,255, 0.5);}
}

@mixin navbarIcons($selector, $x: 0px, $y:-16px){
    #{$selector}.nav-ico-payment        { background-position: $x $y*9; }
    #{$selector}.nav-ico-client         { background-position: $x $y*8; }
    #{$selector}.nav-ico-monitor        { background-position: $x $y*7; }
    #{$selector}.nav-ico-supervision    { background-position: $x $y*6; }
    #{$selector}.nav-ico-order-log      { background-position: $x $y*5; }
    #{$selector}.nav-ico-member         { background-position: $x $y*3; }
    #{$selector}.nav-ico-annoucment     { background-position: $x $y*4; }
    #{$selector}.nav-ico-actual-auction { background-position: $x $y*2; }
    #{$selector}.nav-ico-users          { background-position: $x $y*1; }
    #{$selector}.nav-ico-all-auction    { background-position: $x $y*0; }
}

@mixin annoucments(){
    position: absolute;
    padding: $bigger-round*2;
    border-radius: $bigger-round;
    top: 76px;
    right: 370px;
    background: $white-font;
    font-family: $aplication-font;
}

@mixin checkboxStyle($placement){
    height:$inter-margin;
    width:$inter-margin;
    margin-left: calc(50% - #{$placement});
}
@mixin borderIf(){
    tbody tr td.bordered,
    tfoot tr td.bordered,
    thead tr th.bordered{
        border-right:$table-border !important;
    }
}
@mixin phases($float, $margin){
    width:$small-margin;
    height:$small-margin;
    display: inline-block;
    border-radius:50%;
    float: #{$float};
    margin-top: 2px;
    @if $margin == 5px {
        margin-right: #{$margin};
    }
    &.NEW{background:$new-color;}
    &.SETTLED{background:$settled-color;}
    &.RUNNING{background:$running-color;}
    &.FINISHED{background:$finished-color;}
    &.PUBLISHED{background:$published-color;}
    &.INVALIDATED{background:$invalidated-color;}
    &.REQUIRES_CONFIRMATION{background:$confirmation-color;}
    &.INVALIDATED_DISTRIBUTED{background:$invalidated-color;}
}

