.submission-profile-input {
    min-width: 70px;
}

.submission-profile-table {
    .form-group {
        margin-bottom: 0;
    }
}

.reduction-table {
    margin-top: -15px;
}

.reduction-input {
    text-align: center;
    min-width: 140px;
}

.documentation-table-cell {
    height: 31px;
}


.submission-parameters-table {
    width: 50%;
    margin-left: 0 !important;
}

.row-width tr td:nth-child(1) {
    width: 30% !important;
}
.row-width tr td:nth-child(2) {
    width: 70% !important;
}

.tariff-group-description {
    margin-left: 3%;

    .service-type-description {
        max-width: 205px;
        min-width: 205px;
    }

    span:nth-child(2) {
        margin-left: 5px;
    }
}

.allocation-decision .control-group .form-group {
    width: 93% !important;
}

.width-15 {
    width: 15% !important;
}

.member-submissions {
    form {
        margin-top: 15px
    }

    .control-group {
        display: flex;
    }

    .control-group .form-group {
        width: 320px;
        display: flex;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .control-group .form-group label {
        width: 80px;
        padding-top: 5px;
        padding-right: 10px;
    }


    .control-group .form-group .input-group ul.dropdown-menu {
        width: 285px;
    }

    .control-group .form-group .hours, .control-group .form-group .minutes {
        margin: auto;
        padding: 5px;
        width: 90px;
    }
}
