.offer-description-ellipsis {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.summation {
    p {
        display: inline-block;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0px;
        margin-right: 50px;
        span {
            margin-right: 10px;
        }
    }
}

.tables {
    margin-top: 10px;
    .table-responsive.gsp-table {
        table.table-striped.table {
            margin: 10px 0 0 0;
        }
    }
}
