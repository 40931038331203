.top-nav {
    background-color: #fff;
    box-shadow: $boxShadowNavbar;
    height: 60px;

    .logo-img {
        position: absolute;
        height: 40px;
        left: 15px;
        top: 10px;
    }

    .digital-clock{
        font-size: 31px;
        font-weight: bold;
        padding-top: 7px;
    }

    .breadcrumb{
        position: absolute;
        left: 190px;
        color: $primary;
        top: 10px;
        font-size: 16px;
        margin: 0;
        background: none;
        border-radius: 0;
        img{
            padding-bottom: 5px;
        }
        .active{
            font-weight: bold;
        }
    }
    .breadcrumb > li + li:before{
        content: '→ ';
        color: $primary;
    }

    .logo {
        padding: 10px;
    }

    ul {
        list-style: none;
        margin-bottom: 0;

        li {
            border-left: $table-border;
            float: left;
            height: 60px;
            padding: 20px 0;
            text-transform: uppercase;

            a {
                color: $primary;
                padding: 20px;

                &:hover {
                    text-decoration: none;
                }
            }

            &.active,
            &:hover {
                box-shadow: inset 0 -5px 0 $accent;
                font-weight: bold;
            }

            &.no-border {
                border: none;
            }

            &.dropdown {
                width: 340px;
                text-align: right;

                .caret {
                    margin-left: 12px;
                    vertical-align: super;
                }

                ul.dropdown-menu {
                    border-radius: 0 0 5px 5px;
                    min-width: 340px;

                    li {
                        border: none;
                        float: none;
                        height: auto;
                        padding: 0;

                        a {
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}
