.sidebar {
    background-color: $white-font;
    border-left: $sidebar-border;
    overflow-y: none;
    position: fixed;
    height: $all-of;
    width: 340px;
    transition: all 0.25s;
    z-index: 1002;
    top: 60px;
    right: 0px;

    .messages {
        background-color: #fff;
        border-bottom: $sidebar-border;
        header + section{
            position: absolute;
            width: 340px;
            top: 100px;
            bottom: 60px;
            overflow-y: auto;
            overflow-x: hidden;
            &.messages-container{
               top:102px;
            }
       }

        header {
            border-bottom: $sidebar-border;

            h1 {
                font-size: $hex-margin;
                font-weight: bold;
                padding: $inter-margin;
                margin: $none-border;
            }

            p {
                color: red;
                font-family: $aplication-font;
                font-size: 13px;
                padding: 2px 10px 2px 15px;
                border: $sidebar-border-red;
                text-align: center;
                width: 265px;
                margin-left: $ten-pixels;
                border-radius: $bigger-round;
                background: $light-grey-background;
                display: inline-flex;
            }
        }

        ul {
            padding: 0 10px;
            @include commentColor(li);
            list-style: none;
            li {
                &.important-message {
                    border: $sidebar-border-red !important;
                }
                border: $sidebar-border;
                border-radius: $bigger-round;
                box-shadow: $boxShadowNavbar;
                list-style: none;
                padding: $ten-pixels;
                margin: 10px 0;

                span {
                    background-color: initial;
                }

                .notificat {
                    display: inline-block;
                    width: 90%;
                    a{
                        color: $accent;
                        font-weight: normal;
                    }
                    span {
                        word-wrap: break-word;
                    }
                }

                .notificat-button {
                    right: $inter-margin;
                    top: $inter-margin;

                    button {
                        border: none;
                        outline: none;
                    }
                }

                .message-date {
                    font-size: 12px;
                }

                p {
                    margin-bottom: $none-border;

                    &.messages-auction {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: $all-of;
                    }
                }



            }
        }

        .refresh {
            padding: 10px 0;
            text-align: center;
            font-size: $small-margin;
            color: #A7A7A7;
            position: absolute;
            bottom: 60px;
            background: #ECECEC;
            width: 100%;
            border-top: $sidebar-border;

            span:focus,
            span:active {
                outline: none;
            }
        }
    }

}
