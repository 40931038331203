.gsp-checkbox,
.gsp-radiobutton {
    label {
        font-weight: normal;
        margin-bottom: 0;
    }
    input[type="checkbox"],
    input[type="radio"] {
        display: none;
        &:focus+label:before,
        &:hover+label:before {
            border-color: $gsp-light-border;
        }
        &:active+label:before {
            transition-duration: 0s;
        }
        &+label {
            position: relative;
            padding-left: 2em;
            vertical-align: top;
            cursor: pointer;
        }
        &+label:before {
            box-sizing: content-box;
            content: '';
            color: $gsp-light-border;
            position: absolute;
            top: 50%;
            left: $none-border;
            width: $small-margin;
            height: $small-margin;
            margin-top: -9px;
            border: 2px solid #fff;
            text-align: center;
            transition: all 0.2s ease;
        }
        &+label:after {
            box-sizing: content-box;
            content: '';
            position: absolute;
            top: 50%;
            left: $round-border;
            width: $ten-pixels;
            height: $ten-pixels;
            margin-top: -5px;
            transform: scale(0);
            transform-origin: 50%;
            transition: transform 100ms ease-out;
        }
        &:disabled+label:before {
            border-color: #cccccc;
        }
        &:disabled:focus+label:before,
        &:disabled:hover+label:before {
            background-color: inherit;
        }
        &:disabled:checked+label:before {
            background-color: #cccccc;
        }
    }
}

.gsp-checkbox,
.gsp-radiobutton {
    input[type="checkbox"] {
        &+label:after {
            background-color: transparent;
            top: 50%;
            left: $round-border;
            width: 8px;
            height: 3px;
            margin-top: -4px;
            border-style: solid;
            border-color: $white-font;
            border-width: 0 0 3px 3px;
            border-image: none;
            transform: rotate(-45deg) scale(0);
        }
        &:checked+label:after {
            content: '';
            transform: rotate(-45deg) scale(1);
            transition: transform 100ms ease-out;
        }
    }
    input[type="radio"] {
        &:checked+label:before {
            animation: borderscale 300ms ease-in;
            background-color: $white-font;
        }
        &:checked+label:after {
            transform: scale(1);
        }
        &+label:before,
        &+label:after {
            border-radius: 50%;
        }
        &:checked+label:before {
            animation: borderscale 200ms ease-in;
        }
    }
}

@keyframes borderscale {
    50% {
        box-shadow: 0 0 0 2px $gsp-light-border;
    }
}
