.import-file-row {
    padding: 0 30px;
}

.attach-file-form {
    min-height: 130px;

    input {
        display: none;
    }
}

.import-dragover {
    margin: 0;
    border: 1px dashed #002D74;
}

.documentation-modal-title {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;

    h5 {
        color: #C6CFDA;
    }
}
