@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Bold.woff2') format('woff2'), url('../fonts/OpenSans-Bold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-BoldItalic.eot');
    src: url('../fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'), url('../fonts/OpenSans-BoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBold.eot');
    src: url('../fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'), url('../fonts/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBoldItalic.eot');
    src: url('../fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'), url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Italic.eot');
    src: url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Italic.woff2') format('woff2'), url('../fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Light.woff2') format('woff2'), url('../fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-LightItalic.eot');
    src: url('../fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-LightItalic.woff2') format('woff2'), url('../fonts/OpenSans-LightItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Regular.woff2') format('woff2'), url('../fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiboldItalic.eot');
    src: url('../fonts/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-SemiboldItalic.woff2') format('woff2'), url('../fonts/OpenSans-SemiboldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}



@font-face {
    font-family: 'Titillium';
    src: url('../fonts/Titillium-Bold-webfont.eot');
    src: url('../fonts/Titillium-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Titillium-Bold-webfont.woff') format('woff'),
         url('../fonts/Titillium-Bold-webfont.ttf') format('truetype'),
         url('../fonts/Titillium-Bold-webfont.svg#titillium_bdbold') format('svg');
    font-weight: bold;
    font-style: normal;

}


@font-face {
    font-family: 'Titillium';
    src: url('../fonts/Titillium-Light-webfont.eot');
    src: url('../fonts/Titillium-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Titillium-Light-webfont.woff') format('woff'),
         url('../fonts/Titillium-Light-webfont.ttf') format('truetype'),
         url('../fonts/Titillium-Light-webfont.svg#titilliumlight') format('svg');
    font-weight: 200;
    font-style: normal;

}


@font-face {
    font-family: 'Titillium';
    src: url('../fonts/Titillium-Semibold-webfont.eot');
    src: url('../fonts/Titillium-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Titillium-Semibold-webfont.woff') format('woff'),
    url('../fonts/Titillium-Semibold-webfont.ttf') format('truetype'),
    url('../fonts/Titillium-Semibold-webfont.svg#titilliumsemibold') format('svg');
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: 'Titillium';
    src: url('../fonts/Titillium-Regular-webfont.eot');
    src: url('../fonts/Titillium-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Titillium-Regular-webfont.woff') format('woff'),
         url('../fonts/Titillium-Regular-webfont.ttf') format('truetype'),
         url('../fonts/Titillium-Regular-webfont.svg#titilliumregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'Titillium';
    src: url('../fonts/Titillium-Thin-webfont.eot');
    src: url('../fonts/Titillium-Thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Titillium-Thin-webfont.woff') format('woff'),
         url('../fonts/Titillium-Thin-webfont.ttf') format('truetype'),
         url('../fonts/Titillium-Thin-webfont.svg#titilliumthin') format('svg');
    font-weight: 100;
    font-style: normal;

}
