$primary:                                   #002D74;
$info:                                      #7DACF6;
$accent:                                    #FF6309;

$colorMainBackground:                        #FBFCFF;
$colorMenuBackground:                        #FFFFFF;
$colorMenuPositionText:                      #fcfcfc;
$colorMenuPositionHoverBg:                   #4a4a4a;
$colorMenuPositionHoverText:                 #fcfcfc;
$colorMenuPositionActiveBg:                  #2c2a2b;
$colorMenuPositionActiveText:                #86bc25;

$tableHeader:                                #DCE1E6;

$colorPanelBackground:                       #fcfcfc;
$colorLogoBackground:                        #fcfcfc;
$colorDefaultDarkNegative:                   #fcfcfc;
$colorDefaultDark:                           #4a990a;
$colorDefaultLight:                          #86bc25;
$colorDefaultOverlay:                        #86bc25;
$colorDefaultSecondary:                      #840032;
$colorFontDefault:                           #707070;
$colorFontTrivial:                           #9c9c9c;
$colorFontDisabled:                          #afafaf;
$colorHighlight:                             #e1e1e1;
$colorIconDefault:                           #cccacb;
$colorIconRemove:                            #840032;
$colorNotification:                          #840032;
$colorNotificationBorder:                    #4a990a;
$colorNotificationBackground:                #fcfcfc;
$colorError:                                 #b73e3e;
$colorErrorNegative:                         #ffffff;
$colorTableHeaderBgTop:                      #e1e0e0;
$colorTableHeaderBgBottom:                   #e1e0e0;
$colorTableHeaderCellBorder:                 #c1c1c1;
$colorTableFilterBorder:                     #c1c1c1;
$colorTableHeaderText:                       #231f20;
$colorTableCellBorder:                       #d9d9d9;
$colorTableEvenBackround:                    #f2f2f2;
$colorTableOddBackround:                     #fcfcfc;
$colorTableCurrentRowBg:                     #f1f7e9;
$colorTableAddedRowsBg:                      #7a7a7a;
$colorTableAddedRowsText:                    #fcfcfc;
$colorElementBorder:                         #dcdcdc;
$colorFiltersBackground:                     #f8f8f8;
$colorSuccess:                               #008000;
$colorTableModifiedRowBg:                    #e1f99f;
$tableTreeRow:                               #FFFCDB;
$settlemenRow:                               #FFFCDB;
$relatedRow:                                 #FFEFDB;
$colorPopupTitle:                            #005a9c;
$colorActionIconBackground:                  #f6f9fb;
$colorActionIconBorder:                      #d2d5d7;
$colorDatePickerBackground:                  #fcfcfc;
$colorDatePickerHeaderBackground:            #005a9c;
$colorDatePickerHeaderText:                  #fcfcfc;
$colorDatePickerOtherMonthDay:               #cccacb;
$colorDatePickerActiveDayBackground:         #005a9c;
$colorDatePickerActiveDayText:               #fcfcfc;
$colorDatePickerHightlight:                  #f2f2f2;
$colorCustomFileButtonBackground:            #549384;
$colorBgUnderPreloader:                      rgba(#ffffff, 0.7);
$colorChartBorder:                           #363636;
$colorNavigationButtonBg:                    #e9e8e8;
$colorNavigationButtonBgHover:               #231f20;
$colorNavigationButtonText:                  #231f20;
$colorNavigationButtonTextHover:             #e9e8e8;
$colorDefaultButtonBg:                       #393536;
$colorDefaultButtonBgHover:                  #231f20;
$colorDefaultButtonText:                     #fcfcfc;
$colorDefaultButtonTextHover:                #86bc25;
$colorFilterToggleButtonBg:                  #e1e0e0;
$colorFilterToggleButtonBgHover:             #231f20;
$colorFilterToggleButtonText:                #231f20;
$colorFilterToggleButtonTextHover:           #e1e0e0;
$colorEvenSectionActionButtonBg:             #231f20;
$colorEvenSectionActionButtonBgHover:        #e1e0e0;
$colorEvenSectionActionButtonText:           #e1e0e0;
$colorEvenSectionActionButtonTextHover:      #231f20;
$colorTrayBg:                                #4a990a;
$colorTrayIcon:                              #231f20;
$colorTrayIconHighlight:                     #fcfcfc;
$colorInfo:                                  #c1c5b0;
$colorTooltipBg:                             rgba(#363636, 0.93);
$colorInputText:                             #231f20;
$colorInputPlaceholderText:                  #d9d9d9;
$colorInputAddonBorder:                      #4a990a;
$colorLoginWindowInputBorder:                #918f8f;
$colorAccordionHeader:                       #e1e0e0;
$colorAccordionBg:                           #fdfdff;
$colorEditorToolbarBg:                       #f8f8f8;
$colorStatusDotYellow:                       #eec91e;
$colorStatusDotGreen:                        #4a990a;
$colorStatusDotGray:                         #c1c1c1;
$colorStatusDotRed:                          #b73e3e;
$colorWarning:                               #bea420;
$colorSelectedNewsBg:                        #E8E8E8;
$borderColorNews:                            #dbdbdb;
$paginationCurrentBackground:                #37393B;
$colorMultiselectClearButtonBg:              #e1e0e0;
$colorMultiselectClearButtonBgHover:         #231f20;
$colorMultiselectClearButtonText:            #231f20;
$colorMultiselectClearButtonTextHover:       #e1e0e0;
$colorMultiselectOptionSelected:             #e9e8e8;
$colorMultiselectOptionHighlighted:          #f1f7e9;
$backgroundUnderModal:                       rgba(#0A0A0A, 0.7);
$balloonBorder:                              #8C8C8C;
$placeholdersjsColor:                        #D9D9D9;
$beigeLight:                                 #FDF8E6;
$beige:                                      #F3F0E7;
$lightGray:                                  #AFAFAF;
$colorStatusDotYellow:                       #eec91e;
$colorStatusDotGreen:                        #4a990a;
$colorStatusDotGray:                         #c1c1c1;
$colorStatusDotRed:                          #b73e3e;
$dateTimeIncorrectDate:                      #FF8041;
$selectizeInputDisable:                      #b5b2b2;
$colorTableUnaccepterRowBg:                  #ffe4ca;
$colorTableBackloadingRowBg:                 #DAEDFF;
