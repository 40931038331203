.bid-info {
    p {
        font-weight: bold;
        &:first-child {
            margin-bottom: 3px;
        }
    }
    .first-col {
        width: 125px;
        display: inline-block;
    }
}