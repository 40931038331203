.table-responsive.gsp-table {
    & .table-striped.table {
        .auction-invalidated {
            text-align: center;
            background: red;
            opacity: 0.5;
            font-family: $aplication-font;
            font-size: $twenty-pixels;
            color: white;
            font-weight: bold;
            padding: 5px 2px 8px 2px;
        }
        margin: 20px 0;
        border-radius:$bigger-round;
        -webkit-border-radius: $bigger-round;
        -moz-border-radius: $bigger-round;
        border-spacing: $none-border;
        border-collapse: separate;
        border:$none-border;
        & thead tr {
            &:not(:last-child) th {
                border-bottom: $none-border;
            }
            th.rowspanned {
                border-bottom: $table-border;
            }
            &:last-child {
                th {
                    &.bid-settlement-price {
                        width: 160px;
                    }
                }
            }
            &:first-child {
                & th {
                    &:last-child {
                        -webkit-border-top-right-radius: $round-border;
                        border-top-right-radius: $round-border;
                        -moz-border-radius-top-right: $round-border;
                    }
                    &:first-child {
                        -webkit-border-top-left-radius: $round-border;
                        border-top-left-radius: $round-border;
                        -moz-border-radius-top-left: $round-border;
                    }
                }
            }
            & th {
                padding: $round-border;
                background: $tableHeader;
                border: $table-border;
                vertical-align: middle;
                font-family: $aplication-font;
                color: #344466;
                font-size: $small-margin;
                font-weight: 800;
                line-height: $inter-margin;
                text-align: center;
                & input[type=checkbox] {
                    width: $inter-margin;
                    height: $inter-margin;
                }
                &:not(:last-child) {
                    border-right: $none-border;
                }
                &.bordered {
                    border-right: $table-border;
                }
                &.collateral-currency {
                    width: 110px;
                }
                &.filter-header {
                    min-width: 200px;
                    width: 200px;
                    span {
                        float: left;
                        padding-top: 9px;
                        margin-right: $ten-pixels;
                    }
                    span+input {
                        width: calc(100% - 70px);
                        font-weight: normal;
                    }
                }
            }
        }
        & tbody {
            &.gsp-table-details {
                & tr td {
                    width: 50%;
                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
            &:not(:last-child){
                &.tbody-down td{
                    border-bottom: 1px solid white;
                }
            }
            tr {
                background-color: $white-font;
                &.hide-members{
                    display: none;
                }
                &:nth-child(even) {
                    background-color: #FBFBFB;
                }
                &:nth-child(odd) {
                    border-color: #d8d8d8;
                }
                &:last-child {
                    & td:first-child {
                        -webkit-border-bottom-left-radius: $round-border;
                        border-bottom-left-radius: $round-border;
                        -moz-border-radius-bottom-left: $round-border;
                        border-top: $none-border;
                    }
                    & td:last-child {
                        -webkit-border-bottom-right-radius: $round-border;
                        border-bottom-right-radius: $round-border;
                        -moz-border-radius-bottom-right: $round-border;
                        border-top: $none-border;
                    }
                }
                &.no-bids td {
                    text-align: center;
                }
                &.group-header td {
                    background: $gsp-blue-light;
                    color: $white-font;
                    font-size: $inter-margin;
                    &:first-child {
                        span {
                            font-size: $twenty-pixels;
                            color: $white-font;
                            outline: none;
                            float: left;
                            padding: 0 3px;
                            margin-right: $twenty-pixels;
                        }
                        label {
                            vertical-align: sub;
                        }
                    }
                    &:not(:first-child) {
                        border-left: $none-border;
                    }
                }
                & td {
                    &.phase-td {
                        min-width: 130px;
                        width: 130px;
                    }
                    padding: $round-border;
                    border:1px solid #b8c4d0;
                    border-collapse: separate;
                    font-family: $aplication-font;
                    color: #344466;
                    font-size: $small-margin;
                    font-weight: 500;
                    line-height: $inter-margin;
                    text-align: left;
                    border-top:$none-border;
                    &.FULL_GRANTED {
                        background: rgba(0, 205, 0, 0.3);
                    }
                    &.PART_GRANTED {
                        background: rgba(205, 155, 0, 0.3);
                    }
                    &.REJECTED {
                        background: rgba(205, 0, 0, 0.3);
                    }
                    & div.phase {
                        @include phases(right, 0);
                    }
                    &:not(:last-child) {
                        border-right: $none-border;
                    }
                    input[type=checkbox] {
                        @include checkboxStyle(8px);
                    }
                }
                &.enter-auction {
                    background: $gsp-blue-very-light;
                }
                &.group-member-header {
                    border-bottom: $table-border;
                }
                &.group-member, &.single-member{
                    &:not(:last-child){
                        border-bottom: $table-border;
                    }
                    background: #F0F0F0;
                }
                &.empty-tr-group {
                    &.tbody-hide td{
                        border-bottom: 2px solid white;
                    }
                    td{
                        padding: $bigger-round;
                        background: $gsp-blue-light;
                        color: $white-font;
                        font-weight: bold;
                        span:first-child{
                            margin-right: $ten-pixels;
                        }
                    }
                }
                &.bid-locked {
                    background-color: #b8b8b8;
                }
            }
        }
        tfoot {
            tr {
                &.summation {
                    background: $white-font;
                    td {
                        &:not(:last-child) {
                            border-right: $none-border;
                            border-left: $none-border;
                        }
                        &:last-child {
                            border-left: $none-border;
                        }
                        &:first-child {
                            border-left: $table-border;
                        }
                        &.sum-label {
                            text-align: right;
                            font-weight: bold;
                            font-size: $hex-margin;
                            font-family: $aplication-font;
                        }
                        &.sum-container {
                            @extend .sum-label;
                            text-align: left;
                        }
                    }
                }
            }
        }
        @include borderIf;
        &.inside-table {
            margin: 0 0 !important;
            table-layout: fixed;
            border-collapse: collapse;
            tr {
                height: 29px;
                &:not(.empty-tr-group) td{
                    padding: 1px;
                    &:first-child{
                        padding-left: $bigger-round;
                    }
                }
                &.client-member-group {
                    background: #F0F0F0;
                }
                &.client-member{
                    border-bottom: $table-border;
                    background: #D8D8D8;

                }
                &.client-single{
                    border-bottom: $table-border;
                    background: #C8C8C8;
                }
                td{
                    border-collapse: collapse;
                    border-left: $table-border;
                    border-right: $table-border;
                    &:not(:first-child){
                        text-align: center;
                    }
                    &:first-child {
                        border-left: $none-border;
                    }
                    &:last-child{
                        border-right: $none-border;
                    }
                    .foo{
                        margin-top: $none-border;
                        margin-bottom: $none-border;
                    }
                }
                &:last-child td{
                    border-bottom: $none-border;
                }
            }
        }
        &.supervisor-specjal-table{
            table-layout: fixed;
            border-collapse: collapse;
            td{
                a{
                    text-decoration: none;
                    color:black;
                }
            }
        }
    }

}

tfoot {
    tr {
        &.summation {
            background: $white-font;
            td {
                &:not(:last-child) {
                    border-right: $none-border;
                    border-left: $none-border;
                }
                &:last-child {
                    border-left: $none-border;
                }
                &:first-child {
                    border-left: $table-border;
                }
                &.sum-label {
                    text-align: right;
                    font-weight: bold;
                    font-size: $hex-margin;
                    font-family: $aplication-font;
                }
                &.sum-container {
                    @extend .sum-label;
                    text-align: left;
                }
            }
        }
    }
}

.table-fixed-header {
    tbody tr:last-child td:first-child {
        border-bottom-left-radius: $none-border !important;
    }
    tbody tr:last-child td:last-child {
        border-bottom-right-radius: $none-border !important;
    }
}

.sticky-footer {
    position: relative;
    top: -40px;
}

.gsp-button-container {
    margin-left: auto;
    margin-right: auto;
    display: table;
    min-width: 55px;
    &.single-button {
        min-width: 25px;
    }
    & button {
        border: none;
        &:hover,
         :focus,
         :active {
            outline: 0 !important;
        }
        &[disabled] {
            opacity: 0.5;
            cursor: no-drop;
        }
    }
}

@media screen and (max-width: 767px){
    .table-responsive{
        border:none;
    }
}

