.ps-container>.ps-scrollbar-y-rail {
    z-index: 1002;
}

.ps-container>.ps-scrollbar-x-rail,
.ps-container>.ps-scrollbar-y-rail {
    opacity: 0.6;
}

.table-fixed-header-wrapper {
    margin-top: 20px;
    table {
        margin: 0!important;
    }
}

.auto-width {
    width: auto!important;
}

.table-fixed-header-wrapper {
    table {
        tfoot {
            display: none;
        }
    }
}