.left-menu {
    background-color: $gsp-blue;
    height: $all-of;
    left: 0px;
    min-height: 400px;
    overflow: hidden;
    position: fixed;
    width: 60px;
    transition: all 0.25s;
    z-index: 1031;

    &:hover {
        width: 230px;

        header {
            width: 100%;
        }

        nav {
            .nav {
                li {
                    &.active{
                        ul{
                            display: block;
                        }
                    }
                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }

    header {
        box-shadow: 4px 2px 5px 0px rgba(62, 75, 160, 0.11);
        background-color: #fff;
        padding: 10px;
        width: 100%;
        overflow: hidden;

        .menu-logo {
            transition: width 0.25s;
            padding-left: 5px;
            height: 40px;
        }
    }

    nav {
        .nav {
            li {
                color: #fff;
                font-size: 14px;
                padding: 10px 0;
                text-indent: 15px;
                white-space: nowrap;

                &:hover, &.active {
                    background-color: $accent;
                }

                a{
                    padding: 0px 0;
                    color: #fff;
                    text-decoration: none;
                    &:hover, &:focus, &:active{
                        background: none;
                    }
                }

                img {
                    margin-right: 10px;
                }

                span {
                    transition: opacity 0.25s;
                    opacity: 0;
                }

                ul {
                    display: none;
                    list-style: none;
                    padding-left: 0px;
                    margin-top: 20px;
                    li {
                        text-indent: 30px;
                        &:hover, &.active{
                            box-shadow: inset 0 -5px 0 #fff;
                        }
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
