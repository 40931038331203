.close {
    opacity: 1;
}
.modal {
    .dropdown-pagination {
        right: 15px;
    }
}

.gsp-modal {
    .additional-margin-space {
        margin-left: 240px;
        margin-top: -5px;
    }
    .header-for-form {
        margin-left: 3%;
        width: 94%;
        border-bottom: 1px solid $gsp-blue;
        margin-bottom: $inter-margin;
        padding-bottom: $bigger-round;
    }
    .modal-from-container {
        border: 1px solid;
        border-color: $gsp-blue-very-light;
        border-top: none;
        border-radius: $bigger-round;
        margin-bottom: $inter-margin;
        .title-container {
            padding-bottom: $bigger-round;
            padding-left: $twenty-pixels;
            padding-top: $bigger-round;
            display: block;
            color: $white-font;
            font-size: $twenty-pixels;
            border-radius: $bigger-round;
            background-color: $accent;
            [type='checkbox'] {
                visibility: hidden;
            }
            label {
                font-style: $aplication-font;
                cursor: pointer;
                float: right;
                padding-right: $ten-pixels;
            }
        }
        .hidden-border {
            border-bottom: none;
        }
        .show-border {
            margin-top: $inter-margin;
            .add-bod-member {
                margin: 0 3% 15px;
            }
        }
    }
    .client-annoucment {
        margin-bottom: $twenty-pixels;
        span {
            border: $gsp-light-border;
            border-radius: $bigger-round;
            padding: $bigger-round;
            font-weight: bold;
        }
    }
    .text-area {
        font-family: $aplication-font;
        font-weight: bold;
        font-size: $small-margin;
        max-height: 200px;
        overflow-y: auto;
        margin-top: $twenty-pixels;
        margin-bottom: $twenty-pixels;
        span {
            &:first-child {
                font-size: $hex-margin;
                position: absolute;
                top: $ten-pixels;
            }
            &:last-child {
                position: absolute;
                bottom: $ten-pixels;
            }
        }
        ul {
            list-style-type: none;
            &:first-of-type {
                margin-top: $inter-margin;
            }
            li:before {
                color: $gsp-blue;
                content: "* ";
                vertical-align: text-top;
                font-size: $inter-margin;
            }
        }
    }
    .control-group {
        display: flex;
        .form-group {
            width: 45%;
            display: flex;
            margin-left: 3%;
            &.checkbox-group-container {
                .title {
                    min-width: 200px;
                }
                input[type='checkbox']+label {
                    min-width: 50px;
                    width: auto;
                    padding-right: $twenty-pixels;
                }
            }
            &.wide {
                width: 93%;
                div.selectize-control {
                    width: $all-of !important;
                }
                .help-block {
                    left: calc(93% + 3px);
                    ;
                }
            }
            #strength {
                width: $all-of;
                display: inherit;
                small {
                    min-width: 210px;
                    font-family: $aplication-font;
                    font-weight: bold;
                    font-size: $all-of;
                }
                ul {
                    margin-left: -40px;
                }
            }
            label {
                &.file-title {
                    padding-left: $inter-margin;
                    word-wrap: break-word;
                }
                width:210px;
                padding-top: $bigger-round;
                padding-right: $ten-pixels;
                &[required]:after {
                    content: " *";
                    color: red;
                }
            }
            .hours,
            .minutes {
                margin: auto;
                padding: $bigger-round;
                width: 90px;
            }
            p.help-block {
                background: #ffffff;
                border: 1px solid red;
                border-radius: $bigger-round;
                box-shadow: 1px 3px 6px rgba(100, 0, 0, 0.4);
                font-size: 12px;
                height: 34px;
                left: calc(50% - 190px);
                margin-top: 25px;
                padding: 8px;
                position: absolute;
                white-space: pre;
                z-index: 1000;
                &:before {
                    content: url("../images/warning.png");
                }
            }
            &:not(:only-child):last-child {
                p.help-block {
                    left: calc(50% + 115px);
                }
            }
            input:not([type='checkbox']),
            select,
            .input-group,
            .selectize-control,
            textarea {
                width: calc(100% - 220px);
            }
            .capitalize {
                text-transform: capitalize;
            }
            .input-group input,
            .input-group select {
                width: $all-of;
            }
            input[type='checkbox'] {
                margin-right: 8px;
                margin-top: 6px;
                width: $hex-margin;
                height: $hex-margin;
            }
            .btn-file {
                position: relative;
                overflow: hidden;
                background: $gsp-blue-very-light;
                border-radius: $round-border;
                height: 34px;
                span {
                    color: $white-font;
                    font-family: $aplication-font;
                    cursor: pointer;
                }
                input[type=file] {
                    position: absolute;
                    top: $none-border;
                    right: $none-border;
                    min-width: $all-of;
                    min-height: $all-of;
                    text-align: right;
                    filter: alpha(opacity=0);
                    opacity: 0;
                    outline: none;
                    background: white;
                    cursor: inherit;
                    display: block;
                }
            }
            input[name='password'] {
                border-radius: $round-border;
            }
            input[type='checkbox']+label {
                min-width: 200px;
            }
            .input-group {
                .input-group-btn .calendar {
                    background: url("../images/calendar.png");
                    height: 34px;
                    width: 40px;
                    background-repeat: no-repeat;
                    background-position: 50% 40%;
                }
                ul.dropdown-menu {
                    width: 285px;
                    .am-pm button {
                        width: 34px;
                        height: 34px;
                    }
                }
                select {
                    border-radius: $round-border;
                }
            }
            .selectize-input {
                .item {
                    word-break: break-word;
                }
                input {
                    padding-right: $twenty-pixels !important;
                }
            }
        }
    }
    .button-right {
        text-align: right;
    }
    .label-description {
        margin: 5px 0 0 3%;
    }
    .table-responsive {
        margin: 0 25px;
        &.table-description {
            margin-top: -10px;
        }
        &.table-reduction {
            max-width: 54%;
            margin-top: -15px;
        }
        &.full { margin: 0; }
        & .left-side {
            margin: 0 0 0 25px;
        }
        & .right-side {
            margin: 0 25px 0 0;
        }
    }
    &.infinite-container {
        .table-responsive {
            max-height: 350px;
            overflow-y: auto;
            min-height: 350px;
            margin-top: $twenty-pixels;
            .table.table-striped.table-bordered {
                margin-top: $none-border;
            }
        }
    }
    .selectize-dropdown.single .selectize-dropdown-content {
        max-height: 90px;
    }
}

.modal-header {
    border-bottom: 1px solid #e5e5e5;
    background-color: $accent;
    border-top-right-radius: $bigger-round;
    border-top-left-radius: $bigger-round;
    .modal-title {
        color: $white-font;
        font-size: 24px;
        word-wrap: break-word;
    }
    button.close {
        width: $medium-margin;
        height: $medium-margin;
        margin-bottom: -5px;
        font-size: $medium-margin;
        outline: none;
    }
}

modal-footer-left-button{
    float: left;
    flex: 50%;
}
.modal-footer-right-button{
    float: right;
    flex: 50%;
}

.modal-footer {
    display: flex;
    .btn.btn-primary,
    .btn.btn-primary:hover,
    .btn.btn-primary:focus,
    {
        background-color: $gsp-blue !important;
        border: none;
        color: $white-font;
        font-family: $aplication-font;
    }
    .btn.btn-default,
    .btn.btn-default:hover,
    .btn.btn-default:focus  {
        background-color: black !important;
        border: none;
        color: $white-font;
        font-family: $aplication-font;
    }
    p.help-block {
        position: absolute;
        right: $inter-margin;
        font-size: 12px;
        word-break: break-word;
        background: $white-font;
        z-index: 1000;
        border-radius: $bigger-round;
        border: $sidebar-border-red;
        padding: $bigger-round;
        &:before {
            content: url("../images/warning.png");
        }
    }
}

.modal-body {
    .button-right {
        margin-right: calc(3% + 10px);
    }
}

.contact {
    text-align: center;
    margin: 5% 0 5% 0;
    h2,
    h3,
    h4 {
        font-weight: bold;
    }
}

@media screen and (max-width: 992px) {
    .modal-dialog {
        min-width: 772px;
        .gsp-modal {
            p.help-block {
                margin-left: 100px !important;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-lg {
        min-width: 920px;
    }
}

@media screen and (max-width: 1100px) {
    .form-group.import-group {
        p.help-block {
            left: calc(50% + 15px) !important;
            margin-left: 100px !important;
        }
    }
}

@-moz-document url-prefix() {
    .gsp-modal {
        .control-group {
            .form-group {
                select {
                    margin-right: 1px;
                }
            }
        }
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .gsp-modal {
        .alerts {
            position: absolute !important;
            top: $inter-margin !important;
        }
    }
}
