.login-form {
    max-width: 560px;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img{
        max-width: 300px;
    }

    h2 {
        color: #000;
        font-size: 36px;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    h3 {
        color: #000;
        margin-top: 0;
        margin-bottom: 25px;
    }

    form {
        width: 240px;
        margin: 0 auto;

        .form-control {
            height: 42px;
            margin: 12px 0;
        }

        button {
            height: 42px;
            text-transform: uppercase;
        }
    }

    .forgot-password {
        margin-top: 30px;
        margin-bottom: 5px;
    }
}

footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 20px;
    background-color: #fff;
    text-align: center;
}
